<template>
  <component
    :is="component"
    v-if="story.content"
    :blok="story.content"
  />
</template>

<script setup lang="ts">
import { useCookie } from '#app'
const redesign = useCookie('wt-redesign-homepage-cookie')
const component = computed(() => redesign.value ? resolveComponent('AbHomeRedesign') : resolveComponent('HomeLayout'))
const runtimeConfig = useRuntimeConfig()
const story = await usePage({
  bridgeOptions: {
    resolveRelations: ['homeSection.links'],
    resolveLinks: 'url',
  },
})

useHead({
  link: [
    { rel: 'alternate', hreflang: 'en-gb', href: runtimeConfig.public.cmsUrl },
    { rel: 'alternate', hreflang: 'en-us', href: runtimeConfig.public.usCmsUrl },
  ],
})

useSchemaOrg([
  defineOrganization({
    name: 'Unbiased',
    alternateName: 'Unbiased.co.uk',
    telephone: '+44-800-020-9430',
    logo: '~/assets/images/logo.svg',
    sameAs: [
      'https://www.linkedin.com/company/683058',
      'https://twitter.com/unbiaseduk',
      'https://plus.google.com/+Unbiased',
      'https://www.youtube.com/channel/UCziC5nQ17nPzQUtnaL9DODw',
      'https://www.facebook.com/unbiaseduk',
      'https://en.wikipedia.org/wiki/Unbiased.co.uk',
      'https://www.instagram.com/unbiaseduk/',
    ],
  }),
  defineAddress({
    streetAddress: '32-38 Saffron Hill',
    addressLocality: 'London',
    postalCode: 'EC1N 8FH',
    addressCountry: 'GB',
    alternateName: 'unbiased',
  }),
  defineAggregateRating({
    ratingValue: '4',
    bestRating: '5',
    ratingCount: '10681',
    worstRating: '1',
  }),
])
</script>
